import {Source} from '@interfaces';
import {get_result,fetchIt} from 'shared-functions';
import {tdd_navbar_props_url, tdd_directory_lists_url, tdd_directory_path_url, tdd_directory_link_url, tdd_valid_directory_links, tdd_valid_directory_paths, tdd_directory_count_url, tdd_media_url, tdd_metadata_url, tdd_image_url, tdd_tweet_url, tdd_social_post_url, tdd_source_data_url,tdd_pdf_viewer_info_url,tdd_valid_pdf_links_url,tdd_valid_path_items} from './tdd_path_utils';
import {if_log} from '@functions';
const logsOn=true

export async function fetchNavbarProps(): Promise<any> {
  const url = tdd_navbar_props_url;
  const response = await fetchIt(url);
  if_log(logsOn,['fetchNavbarProps response:', response]);
  return get_result(response);
}

export async function fetchDirectoryList(directory: string | null = null): Promise<any> {
  if_log(logsOn,['Fetching api for thedailydialectics directory:', directory]);
  const url = `${tdd_directory_lists_url}?directory=${encodeURIComponent(directory || '')}`;
  const response = await fetchIt(url);
  if_log(logsOn,['fetchDirectoryList response:', response]);
  return response;
}

export async function fetchDirectoryPath(directory: string | null = null): Promise<any> {
  if_log(logsOn,['Fetching api for thedailydialectics directory:', directory]);
  const url = `${tdd_directory_path_url}?directory=${encodeURIComponent(directory || '')}`;
  const response = await fetchIt(url);
  if_log(logsOn,['fetchDirectoryPath response:', response]);
  return get_result(response);
}

export async function fetchDirectoryLink(directory: string | null = null): Promise<any> {
  if_log(logsOn,['Fetching api for thedailydialectics directory:', directory]);
  const url = `${tdd_directory_link_url}?directory=${encodeURIComponent(directory || '')}`;
  const response = await fetchIt(url);
  if_log(logsOn,['fetchDirectoryLink response:', response]);
  return get_result(response);
}
export async function fetchDirectoryLinks(directory: string | null = null,string: string | null = null): Promise<any> {
  if_log(logsOn,['Fetching api for thedailydialectics directory:', directory]);
  const url = `${tdd_valid_directory_links}?directory=${encodeURIComponent(directory || '')}&string=${encodeURIComponent(string || '')}`;
  const response = await fetchIt(url);
  if_log(logsOn,['fetchDirectoryLinks response:', response]);
  return get_result(response);
}
export async function fetchDirectoryPaths(directory: string | null = null,string: string | null = null): Promise<any> {
  if_log(logsOn,['Fetching api for thedailydialectics directory:', directory]);
  const url = `${tdd_valid_directory_paths}?directory=${encodeURIComponent(directory || '')}&string=${encodeURIComponent(string || '')}`;
  const response = await fetchIt(url);
  if_log(logsOn,['fetchDirectoryPaths response:', response]);
  return get_result(response);
}


export async function fetchValidPathItems(directory:any=null,string:any=null,get_urls:any=null,get_dirnames:any=null,exclude_folder:any=null,ext:any=null,recursive:any=null): Promise<any> {
  recursive  = recursive || true
  ext = ext || 'pdf';
  exclude_folder = exclude_folder || 'pdf_pages'
  get_dirnames = get_dirnames || true
  get_urls = get_urls || false
  directory = directory || 'pdfs'
  const url = `${tdd_valid_path_items}`;
  const body = {directory,string,get_urls,get_dirnames,exclude_folder,ext,recursive}
  const response = await fetchIt(url, body, null,null, 'POST');
  if_log(logsOn,['fetchValidPathItems response:', response]);
  return get_result(response);
}
export async function fetchDirectoryCount(format:string,contentDir: string | null = null): Promise<any> {
  if_log(logsOn,['Fetching api for thedailydialectics directory:', contentDir]);
  const url = `${tdd_directory_count_url}?format=${encodeURIComponent(format || '')}&contentDir=${encodeURIComponent(contentDir || '')}`;
  const response = await fetchIt(url);
  if_log(logsOn,['fetchDirectoryCount response:', response]);
  return get_result(response);
}

export async function fetchMediaData(): Promise<any> {
  const url = `${tdd_media_url}`;
  const response = await fetchIt(url);
  if_log(logsOn,['fetchMediaData response:', response]);
  return get_result(response);
}

export async function fetchMetaData(data:any=null,partial_path:any=null,path:any=null): Promise<any> {
  const url = `${tdd_metadata_url}`;
  const body = {data,partial_path,path}
  const response = await fetchIt(url, body, null,null, 'POST');
  if_log(logsOn,['fetchMetaData response:', response]);
  return get_result(response);
}
export async function fetchPdfViewerInfo(directory:any): Promise<any> {
  const url = `${tdd_pdf_viewer_info_url}`;
  const body = {directory}
  const response = await fetchIt(url, body, null,null, 'POST');
  if_log(logsOn,['fetchPdfViewerInfo response:', response]);
  return get_result(response);
}

export async function fetchValidPdfs(directory:any=null,dir_only:boolean | null=null): Promise<any> {
  const url = `${tdd_valid_pdf_links_url}`;
  const body = {directory,dir_only}
  if_log(logsOn,[`directory = ${directory},dir_only = ${dir_only}`]);
  const response = await fetchIt(url, body, null,null, 'POST');
  if_log(logsOn,['fetchValidPdfs response:', response]);
  return get_result(response);
}

export async function fetchImageComponent(directory:string,width:any=null,height:any=null,background:any=null,meta:any=null,captionIt:any=true): Promise<any> {
  directory = directory || ''
  width = width || ''
  height = height || ''
  background = background || ''
  meta = meta || {}
  captionIt = captionIt || null
  const body = {directory,width,height,background,captionIt}
  if_log(logsOn,[`directory = ${directory},width = ${width},height = ${height},background = ${background},captionIt = ${captionIt}`]);
  const response = await fetchIt(tdd_image_url, body, null,null, 'POST');
  if_log(logsOn,['fetchImageComponent response:', response]);
  return get_result(response);
}

export async function fetchTweetData(description:any=null,title:any=null,link_address:any=null,keywords:any=null,pageTitle:any=null,path:any=null): Promise<any> {
  const url = `${tdd_tweet_url}`;
  const body = {description,title,link_address,keywords,pageTitle,path}
  if_log(logsOn,[`description = ${description},title = ${title},link_address = ${link_address},keywords = ${keywords},pageTitle = ${pageTitle},path = ${path}`]);
  const response = await fetchIt(url, body, null,null, 'POST');
  if_log(logsOn,['fetchTweetData response:', response]);
  return get_result(response);
}


export async function fetchSocialPostUrl(platform:any=null,text:any=null,url:any=null,via:any=null,hashtags:any=null): Promise<any> {
  const api_url = `${tdd_social_post_url}`;
  const body = {platform,text,url,via,hashtags}
  if_log(logsOn,[`platform = ${platform},text = ${text},url = ${url},via = ${via},hashtags = ${hashtags}`]);
  const response = await fetchIt(api_url, body, null,null, 'POST');
  if_log(logsOn,['fetchSocialPostUrl response:', response]);
  return get_result(response);
}


export async function renderSources(sources: Source[]): Promise<any> {
  if_log(logsOn,['Fetching metadata for sources:', sources]);
  const url = `${tdd_source_data_url}`; // Adjust URL as needed
  const response = await fetchIt(url, { sources }, null, null,'POST'); // Use POST with body
  if_log(logsOn,['renderSources response:', response]);
  return get_result(response); // Assume this returns Source[]
}
